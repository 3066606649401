import { navigateTo } from '@/utils/navigateTo';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';
import { RecipientType } from '@gik/create/enums/RecipientType';
import { useCreateAnalyticsController } from '@gik/create/hooks/useCreateAnalyticsController';
import { translationKeys } from '@gik/homepage/i18n/en';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import { Select } from '@gik/ui/Select';
import React from 'react';

// TODO remove this and have an endpoint from backend exposing this info
const createPageOptions = [
  {
    label: i18n.t(translationKeys.createPageMyself),
    value: RecipientType.Myself,
  },
  {
    label: i18n.t(translationKeys.createPageSomeoneElse),
    value: RecipientType.SomeoneElse,
  },
  {
    label: i18n.t(translationKeys.createPageOrganization),
    value: RecipientType.Organization,
  },
];

export interface ICreatePageForProps {
  trackId: CreateClickedLocation;
}

const blockName = 'create-page-for';

export function CreatePageFor({ trackId }: ICreatePageForProps) {
  const bem = useBemCN(blockName);
  const isMd = useBreakpoint(Breakpoint.MD);
  const { trackCreateClicked } = useCreateAnalyticsController();

  const [value, setValue] = React.useState(null);

  function handleCreateClicked() {
    trackCreateClicked(trackId);
    navigateTo(value ? `/create?for=${value}` : '/create');
  }

  return (
    <div {...bem()}>
      <Select
        {...bem('select')}
        placeholder={'Who’s the page for?'}
        options={createPageOptions}
        value={value}
        onChange={setValue}
      />

      <Button {...bem('button')} variant={'secondary'} pill={!isMd} onClick={handleCreateClicked}>
        {i18n.t(translationKeys.getStarted).toString()}
      </Button>
    </div>
  );
}
