import type { Product } from '@gik/core/models/gik/Product';
import type { ProductLDSchema } from '@gik/seo';
import he from 'he';
import { stripHtml } from 'string-strip-html';

export default function productSDGenerator(product: Product): ProductLDSchema {
  if (!product) {
    return null;
  }

  return {
    '@type': 'Product',
    name: product?.name && he.decode(product.name),
    image: product?.images,
    description: product?.shortDescription && stripHtml(he.decode(product.shortDescription)).result,
    sku: product?.sku,
    identifier: product?.slug,
    offers: {
      '@type': 'Offer',
      url: `https://www.giveinkind.com/products/${product?.slug}`,
      availability: 'https://schema.org/InStock',
      priceCurrency: 'USD',
      price: product?.price,
    },
  };
}
