import { useBemCN } from '@gik/core/utils/bemBlock';
import type { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';
import type { IBoxProps } from '@gik/ui/Box';
import { Box } from '@gik/ui/Box';
import React from 'react';
import { StartInkindContent } from './StartInkindContent';

export type StartInkindBoxProps = {
  trackingId?: CreateClickedLocation;
} & IBoxProps;

function StartInkindCardComp({
  className,
  variant = 'white',
  trackingId,
  ...otherProps
}: StartInkindBoxProps): React.ReactElement {
  const bem = useBemCN('start-inkind-card');

  return (
    <Box
      rounded="lg"
      decoration="foliage-small-green"
      {...bem(null, null, className)}
      variant={variant}
      padded
      {...otherProps}
    >
      <div {...bem('content')}>
        <span {...bem('title')}>Ease someone&apos;s life a little.</span>
        <StartInkindContent trackingId={trackingId} />
      </div>
    </Box>
  );
}

export const StartInkindCard = React.memo(StartInkindCardComp);
