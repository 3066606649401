import { useSiteSettings } from '@gik/api/siteSettings/siteSettings';
import { DynamicComponent } from '@gik/core/components/DynamicComponent';
import { withTitle } from '@gik/core/hocs/withTitle';
import type WordpressPost from '@gik/core/models/wordpress/WordpressPost';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Benefits } from '@gik/homepage/components/Benefits/Benefits';
import { Features } from '@gik/homepage/components/Features/Features';
import { HeroComputer } from '@gik/homepage/components/HeroComputer/HeroComputer';
import { HomepageFooter } from '@gik/homepage/components/HomepageFooter/HomepageFooter';
import { HomepageHeader } from '@gik/homepage/components/HomepageHeader/HomepageHeader';
import { StartInkind } from '@gik/homepage/components/StartInkind/StartInkind';
import { InkindUsesSection } from '@gik/marketing/components/InkindUses';
import { HomepageStructuredData } from '@gik/seo/components/StructuredData';
import React from 'react';

const blockName = 'homepage';

export type HomepageServerProps = {
  posts: WordpressPost[];
};

export type HomepageProps = {} & UIComponent;

function HomepageComp() {
  const bem = bemBlock(blockName);
  const { data: siteSettings } = useSiteSettings();

  return withTitle(
    null,
    <HomepageStructuredData>
      <article className={bem()}>
        <DynamicComponent height={599}>
          <HomepageHeader />
        </DynamicComponent>
        {/* {isSiteFeatureEnabled(siteSettings?.enableHomePageFeaturedInkinds) && <FeaturedInkindsSection />} */}
        <DynamicComponent height={2287}>
          <Features />
        </DynamicComponent>
        <DynamicComponent height={854}>
          <Benefits />
        </DynamicComponent>
        <DynamicComponent height={566}>
          <HeroComputer />
        </DynamicComponent>
        <DynamicComponent height={175}>
          <StartInkind />
        </DynamicComponent>
        <DynamicComponent height={618}>
          <InkindUsesSection variant={'solid-neutral'} />
        </DynamicComponent>
        <DynamicComponent height={560}>
          <HomepageFooter />
        </DynamicComponent>
      </article>
    </HomepageStructuredData>
  );
}

export const Homepage = withComponentErrorBoundary(HomepageComp);
