import { isBrowser } from '@/utils/environment';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { AnchorLink } from '@gik/ui/AnchorLink';
import type { UIVariant } from '@gik/ui/types';
import React from 'react';

export interface IFeaturesNavItemProps {
  variant?: UIVariant | 'danger' | 'success' | 'warn';
  tint?: string;
  to: string;
  icon: React.ReactNode;
  label: React.ReactNode;
  activeKey: string;
  setActive: (id: string) => void;
  setInactive: (id: string) => void;
}

export const featuresScrollOffset = -85;

export default function FeaturesNavItemComp({
  to,
  icon,
  label,
  activeKey,
  setActive,
  setInactive,
  variant = 'default',
}: IFeaturesNavItemProps): React.ReactElement {
  const bem = useBemCN('features-nav-item');

  const isActive = activeKey === null ? null : activeKey === to;

  // NOTE: same fix as in ScrollNavBarItem.tsx
  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    if (!isBrowser()) return;

    const activeClass = '.gik-features-nav__icon--active';

    const currentTarget = ev.currentTarget;

    setTimeout(() => {
      const isActiveFinally = !!currentTarget?.querySelector(activeClass);
      !isActiveFinally && window?.scrollBy(0, 2);
    }, 400);
  };

  return (
    <li {...bem(null, [{ [variant]: variant }])}>
      <AnchorLink
        {...bem('link', [{ active: isActive === true }, { inactive: isActive === false }])}
        activeClass={bem('link--active').className}
        onSetActive={() => setActive(to)}
        onSetInactive={() => setInactive(to)}
        to={to}
        spy={true}
        smooth={true}
        offset={featuresScrollOffset}
      >
        <div onClick={handleClick}>
          <div {...bem('icon', [{ active: isActive === true }])}>{icon}</div>
          <span {...bem('label')}>{label}</span>
        </div>
      </AnchorLink>
    </li>
  );
}

export const FeaturesNavItem = withComponentErrorBoundary(FeaturesNavItemComp);
