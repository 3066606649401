import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Heading } from '@gik/ui/Heading';
import type { UIVariant } from '@gik/ui/types';
import React from 'react';

export interface IFeatureContent {
  variant?: UIVariant | 'danger' | 'success' | 'blue' | 'warn';
  title?: React.ReactNode;
  aside?: React.ReactNode;
  description?: React.ReactNode;
  reversed?: boolean;
  titleCentered?: boolean;
  headingLevel?: 2 | 3;
}

export type IFeatureContentProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & IFeatureContent;

function FeatureContentComp({
  variant = 'primary',
  children,
  className,
  title,
  description,
  reversed,
  aside,
  titleCentered,
  headingLevel = 3,
  ...otherProps
}: IFeatureContentProps): React.ReactElement {
  const bem = bemBlock('feature-content');
  const blockClasses = bem(
    null,
    [{ [variant]: variant }, { reversed }, { 'title-centered': titleCentered }],
    className
  );

  const asideWrapper = aside ? <aside>{aside}</aside> : null;

  return (
    <section className={blockClasses} {...otherProps}>
      {reversed && asideWrapper}
      <main>
        {title && (
          <Heading level={headingLevel} noStyling className={bem('title')}>
            {title}
          </Heading>
        )}
        {description && <div className={bem('description')}>{description}</div>}
        {children}
      </main>
      {!reversed && asideWrapper}
    </section>
  );
}

export const FeatureContent = React.memo(withComponentErrorBoundary(FeatureContentComp));
