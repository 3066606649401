import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { BenefitTileProps } from '@gik/homepage/components/BenefitTile/BenefitTile';
import BenefitTile from '@gik/homepage/components/BenefitTile/BenefitTile';
import { translationKeys } from '@gik/homepage/i18n/en';
import i18n from '@gik/i18n';
import { Container } from '@gik/ui/Container';
import { EatOutline } from '@gik/ui/SvgIcon/GikIcons/Eat';
import { ReminderOutline } from '@gik/ui/SvgIcon/GikIcons/Reminder';
import ChatAlt2Icon from '@heroicons/react/outline/ChatAlt2Icon';
import CreditCardIcon from '@heroicons/react/outline/CreditCardIcon';
import EyeOffIcon from '@heroicons/react/outline/EyeOffIcon';
import GiftIcon from '@heroicons/react/outline/GiftIcon';
import LinkIcon from '@heroicons/react/outline/LinkIcon';
import NewspaperIcon from '@heroicons/react/outline/NewspaperIcon';
import SparklesIcon from '@heroicons/react/outline/SparklesIcon';
import UserGroupIcon from '@heroicons/react/outline/UserGroupIcon';
import React from 'react';

const blockName = 'benefits';

function BenefitsComp() {
  const bem = bemBlock(blockName);

  const benefits: BenefitTileProps[] = [
    {
      icon: LinkIcon,
      iconName: 'LinkOutline',
      title: i18n.t(translationKeys.featureShareTitle),
      copy: i18n.t(translationKeys.featureShareCopy),
    },
    {
      icon: UserGroupIcon,
      iconName: 'UserGroupOutline',
      title: i18n.t(translationKeys.featureAddOrganizerTitle),
      copy: i18n.t(translationKeys.featureAddOrganizerCopy),
    },
    {
      icon: EyeOffIcon,
      iconName: 'EyeOffOutline',
      title: i18n.t(translationKeys.featurePrivacyTitle),
      copy: i18n.t(translationKeys.featurePrivacyCopy),
    },
    {
      icon: EatOutline,
      iconName: 'EatOutline',
      title: i18n.t(translationKeys.featureEatTitle),
      copy: i18n.t(translationKeys.featureEatCopy),
    },
    {
      icon: CreditCardIcon,
      iconName: 'CreditCardOutline',
      title: i18n.t(translationKeys.featureCardTitle),
      copy: i18n.t(translationKeys.featureCardCopy),
    },
    {
      icon: GiftIcon,
      iconName: 'GiftOutline',
      title: i18n.t(translationKeys.featureGiftTitle),
      copy: i18n.t(translationKeys.featureGiftCopy),
    },
    {
      icon: NewspaperIcon,
      iconName: 'NewspaperOutline',
      title: i18n.t(translationKeys.featureArticlesTitle),
      copy: i18n.t(translationKeys.featureArticlesCopy),
    },
    {
      icon: ReminderOutline,
      iconName: 'ReminderOutline',
      title: i18n.t(translationKeys.featureRemindersTitle),
      copy: i18n.t(translationKeys.featureRemindersCopy),
    },
    {
      icon: ChatAlt2Icon,
      iconName: 'ChatAlt2Outline',
      title: i18n.t(translationKeys.featureSupportTitle),
      copy: i18n.t(translationKeys.featureSupportCopy),
    },
    {
      icon: SparklesIcon,
      iconName: 'SparklesOutline',
      title: i18n.t(translationKeys.featureFreeTitle),
      copy: i18n.t(translationKeys.featureFreeCopy),
    },
  ];

  return (
    <Container className={bem()} element="section">
      {/* TODO i18n */}
      <h2 className={bem('title')}>Features as unique as your situation</h2>

      <div className={bem('grid')}>
        {benefits.map((feature, key) => (
          <BenefitTile key={key} {...feature} />
        ))}
      </div>
    </Container>
  );
}

export const Benefits = withComponentErrorBoundary(BenefitsComp);
