import Head from '@/components/Head';
import type Article from '@gik/blog/models/Article';
import type Author from '@gik/blog/models/Author';
import type { Product } from '@gik/core/models/gik/Product';
import type {
  ArticleLDSchema,
  FAQPageLDSchema,
  OrganizationLDSchema,
  PersonLDSchema,
  ProductLDSchema,
  ThingLDSchema,
  WithLDSchemaContext,
} from '@gik/seo';
import articleSDGenerator from '@gik/seo/structuredDataContentGenerators/articleSDGenerator';
import authorSDGenerator from '@gik/seo/structuredDataContentGenerators/authorSDGenerator';
import faqSDGenerator from '@gik/seo/structuredDataContentGenerators/faqSDGenerator';
import organizationSDGenerator from '@gik/seo/structuredDataContentGenerators/organizationSDGenerator';
import productSDGenerator from '@gik/seo/structuredDataContentGenerators/productSDGenerator';
import withJSONLDSchemaContext from '@gik/seo/utils/withJSONLDSchemaContext';
import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';

export interface IStructuredDataProps<T extends ThingLDSchema = ThingLDSchema> {
  item: WithLDSchemaContext<T>;
  options?: Parameters<typeof jsonLdScriptProps>[1];
  id?: string;
}

const blockName = 'structured-data';

export default function StructuredData<T extends ThingLDSchema = ThingLDSchema>({
  item,
  options,
  id,
  children,
}: React.PropsWithChildren<IStructuredDataProps<T>>) {
  return (
    <>
      {item && (
        <Head>
          <script id={`${blockName}${id ? `-${id}` : ''}`} {...jsonLdScriptProps<T>(item, options)} />
        </Head>
      )}
      {children}
    </>
  );
}

export interface IArticleStructuredDataProps {
  article: Article;
}

export function ArticleStructuredData({ article, children }: React.PropsWithChildren<IArticleStructuredDataProps>) {
  return (
    <StructuredData<ArticleLDSchema> item={withJSONLDSchemaContext(articleSDGenerator(article))}>
      {children}
    </StructuredData>
  );
}

export interface IAuthorStructuredDataProps {
  author: Author;
}

export function AuthorStructuredData({ author, children }: React.PropsWithChildren<IAuthorStructuredDataProps>) {
  return (
    <StructuredData<PersonLDSchema> item={withJSONLDSchemaContext(authorSDGenerator(author))}>
      {children}
    </StructuredData>
  );
}

export function FAQStructuredData({ children }: React.PropsWithChildren<{}>) {
  return <StructuredData<FAQPageLDSchema> item={withJSONLDSchemaContext(faqSDGenerator())}>{children}</StructuredData>;
}

export interface IProductStructuredDataProps {
  product: Product;
}

export function ProductStructuredData({ product, children }: React.PropsWithChildren<IProductStructuredDataProps>) {
  return (
    <StructuredData<ProductLDSchema> item={withJSONLDSchemaContext(productSDGenerator(product))}>
      {children}
    </StructuredData>
  );
}

export function HomepageStructuredData({ children }: React.PropsWithChildren<{}>) {
  return (
    <StructuredData<OrganizationLDSchema> item={withJSONLDSchemaContext(organizationSDGenerator())}>
      {children}
    </StructuredData>
  );
}
