import bemBlock from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import { PageSection } from '@gik/ui/gik/PageSection';
import { Image } from '@gik/ui/Image';
import { Proportion } from '@gik/ui/KeepProportions';
import React from 'react';

const blockName = 'hero-computer';

export function HeroComputer() {
  const bem = bemBlock(blockName);

  return (
    <PageSection noPad className={bem()}>
      <div className={bem('container')}>
        <Image
          {...{
            className: bem('image'),
            asBackground: true,
            src: `${getAssetsUrl()}homepage/img/hero-comp.png`,
            proportion: new Proportion(1142, 500),
          }}
        />
      </div>
    </PageSection>
  );
}
