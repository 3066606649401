import bemBlock from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Image } from '@gik/ui/Image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';
import { useEffectOnce } from 'react-use';
import useLocation from 'react-use/lib/useLocation';
import type { IFeaturesNavItemProps } from './FeaturesNavItem';
import FeaturesNavItem, { featuresScrollOffset } from './FeaturesNavItem';
import { translationKeys } from './i18n/en';

export const FeaturesNavBlockName = 'features-nav';

export const calendarNavId = 'calendar';
export const wishlistNavId = 'wishlist';
export const updatesNavId = 'updates';
export const donationsNavId = 'donations';
export const moreNavId = 'more';

function FeaturesNavComp(): React.ReactElement {
  const bem = bemBlock(FeaturesNavBlockName);
  const { t } = useTranslation();

  const navItems: Omit<IFeaturesNavItemProps, 'activeKey' | 'setActive' | 'setInactive'>[] = [
    {
      variant: 'primary',
      to: calendarNavId,
      icon: (
        <Image
          style={{ height: '25px' }}
          src={[`${getAssetsUrl()}marketing/icons/calendar.png`, `${getAssetsUrl()}marketing/icons/calendar@2x.png 2x`]}
          alt={t(translationKeys.navCalendar)}
        />
      ),
      label: t(translationKeys.navCalendar).toString(),
    },
    {
      variant: 'danger',
      to: wishlistNavId,
      icon: (
        <Image
          className={bem('wishlist-icon')}
          style={{ height: '25px' }}
          src={[`${getAssetsUrl()}marketing/icons/wishlist.png`, `${getAssetsUrl()}marketing/icons/wishlist@2x.png 2x`]}
          alt={t(translationKeys.navWishlist)}
        />
      ),
      label: t(translationKeys.navWishlist).toString(),
    },
    {
      variant: 'secondary',
      to: updatesNavId,
      icon: (
        <Image
          style={{ height: '25px' }}
          src={[`${getAssetsUrl()}marketing/icons/updates.png`, `${getAssetsUrl()}marketing/icons/updates@2x.png 2x`]}
          alt={t(translationKeys.navUpdates)}
        />
      ),
      label: t(translationKeys.navUpdates).toString(),
    },
    {
      variant: 'success',
      to: donationsNavId,
      icon: (
        <Image
          style={{ height: '25px' }}
          src={[
            `${getAssetsUrl()}marketing/icons/fundraising.png`,
            `${getAssetsUrl()}marketing/icons/fundraising@2x.png 2x`,
          ]}
          alt={t(translationKeys.navFundraising)}
        />
      ),
      label: t(translationKeys.navFundraising).toString(),
    },
    {
      variant: 'warn',
      tint: '700',
      to: moreNavId,
      icon: (
        <Image
          style={{ height: '25px' }}
          src={[`${getAssetsUrl()}marketing/icons/sparkle.png`, `${getAssetsUrl()}marketing/icons/sparkle@2x.png 2x`]}
          alt={t(translationKeys.navMore)}
        />
      ),
      label: t(translationKeys.navMore).toString(),
    },
  ];

  const [activeKey, setActiveKey] = React.useState<string>(null);

  function setActive(id: string): void {
    setActiveKey(id);
  }

  function setInactive(id: string): void {
    // if the scroll leaves the bounds of one of the outer elements it means none of the sections are active
    if (id === 'calendar' && activeKey === 'calendar') {
      setActiveKey(null);
    }
    if (id === 'more' && activeKey === 'more') {
      setActiveKey(null);
    }
  }

  const location = useLocation();

  function scrollToAnchor() {
    const item = navItems.find(item => item.to === location.hash.replace('#', ''));

    if (item) {
      scroller.scrollTo(item.to, {
        spy: true,
        smooth: true,
        offset: featuresScrollOffset,
      });
    }
  }

  React.useEffect(() => {
    scrollToAnchor();
    // eslint-disable-next-line
  }, [location.hash]);

  useEffectOnce(() => {
    // timeout is needed to trigger the scroll to anchor behaviour when navigating to the features page from another page
    setTimeout(() => {
      scrollToAnchor();
    }, 1500);
  });

  return (
    <div className={bem()}>
      <ul>
        {navItems.map((item, i) => (
          <FeaturesNavItem activeKey={activeKey} setActive={setActive} setInactive={setInactive} key={i} {...item} />
        ))}
      </ul>
    </div>
  );
}

export const FeaturesNav = React.memo(withComponentErrorBoundary(FeaturesNavComp));
