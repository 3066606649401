import { getFeaturedInkindPageCategories } from '@/api/inkinds/featuredInkindPageCategories';
import { getFeaturedInkindPages } from '@/api/inkinds/featuredInkinds';
import { getPostsFeatured } from '@/api/posts/postsFeatured';
import { getSiteSettings } from '@/api/siteSettings/siteSettings';
import type { PageLayoutProps } from '@/components/layout/PageLayout';
import { PageLayout } from '@/components/layout/PageLayout';
import type { FallbackProps, GetKeyJson } from '@gik/core/api/BaseAPIConfig';
import { baseFallback, ISRRevalidateTimeout } from '@gik/core/api/BaseAPIConfig';
import { withTitle } from '@gik/core/hocs/withTitle';
import type { WordpressFeaturedInkindPage } from '@gik/core/models/wordpress/WordpressFeaturedInkinds';
import type { WordpressTaxonomy } from '@gik/core/models/wordpress/WordpressTaxonomy';
import { catchSSRError } from '@gik/core/utils/catchSSRError';
import { measureSSRPerformance } from '@gik/core/utils/measureSSRPerformance';
import { Homepage } from '@gik/homepage';
import type { HomepageServerProps } from '@gik/homepage/components/Homepage/Homepage';
import React from 'react';
import { SWRConfig } from 'swr';

export default function HomePageComp({ fallback }: FallbackProps<HomepageServerProps>): React.ReactElement {
  return withTitle(
    null,
    <SWRConfig value={{ fallback }}>
      <Homepage />
    </SWRConfig>
  );
}

HomePageComp.getLayout = function getLayout(page: React.ReactElement, layoutProps: PageLayoutProps) {
  return <PageLayout {...layoutProps}>{page}</PageLayout>;
};

async function _getStaticProps() {
  const siteSettings = getSiteSettings();
  const posts = getPostsFeatured({ slug: 'benefits-others}', perPage: 4 });
  const featuredInkindPageCategories = getFeaturedInkindPageCategories();
  const featuredInkindPageCategoriesJson = await featuredInkindPageCategories.json;

  const firstCategory: WordpressTaxonomy = featuredInkindPageCategoriesJson?.[0];
  let featuredInkinds: WordpressFeaturedInkindPage[];
  let featuredInkindsResponse: GetKeyJson<WordpressFeaturedInkindPage[]>;
  if (firstCategory) {
    featuredInkindsResponse = getFeaturedInkindPages(firstCategory.id);
    featuredInkinds = await featuredInkindsResponse.json;
  }

  return baseFallback(
    {
      ...(siteSettings ? { [siteSettings.key]: await siteSettings.json } : {}),
      ...(posts ? { [posts.key]: await posts.json } : {}),
      ...(featuredInkindPageCategories
        ? { [featuredInkindPageCategories.key]: await featuredInkindPageCategories.json }
        : {}),
      ...(featuredInkindsResponse && featuredInkinds ? { [featuredInkindsResponse.key]: featuredInkinds } : {}),
    },
    undefined,
    ISRRevalidateTimeout.fast
  );
}

export const getStaticProps = catchSSRError(measureSSRPerformance(_getStaticProps, 'HomePage'));
