import bemBlock from '@gik/core/utils/bemBlock';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import React from 'react';

export interface BenefitTileProps {
  icon: SvgrComponent;
  iconName: string;
  title: string;
  copy: string;
}

const blockName = 'benefit-tile';

export default function BenefitTile({ icon, iconName, title, copy }: BenefitTileProps) {
  const bem = bemBlock(blockName);

  return (
    <div className={bem()}>
      <SvgIcon Icon={icon} name={iconName} className={bem('icon')} />
      <div className={bem('title')}>{title}</div>
      <div className={bem('copy')}>{copy}</div>
    </div>
  );
}
