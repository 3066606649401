import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { FeatureTileProps } from '@gik/homepage/components/FeatureTile/FeatureTile';
import { FeatureTile } from '@gik/homepage/components/FeatureTile/FeatureTile';
import { translationKeys } from '@gik/homepage/i18n/en';
import {
  CalendarTestimonial,
  FundraisingTestimonial,
  UpdatesTestimonial,
  WishlistTestimonial,
} from '@gik/homepage/testimonials';
import i18n from '@gik/i18n';
import type { IFeatureContent } from '@gik/marketing/components/FeatureContent';
import { FeatureContent } from '@gik/marketing/components/FeatureContent';
import {
  calendarNavId,
  donationsNavId,
  updatesNavId,
  wishlistNavId,
} from '@gik/marketing/components/FeaturesNav/FeaturesNav';
import { Animation } from '@gik/ui/Animation';
import { onScrollMode } from '@gik/ui/Animation/Animation';
import { Container } from '@gik/ui/Container';
import type { PageSectionProps } from '@gik/ui/gik/PageSection';
import { PageSection } from '@gik/ui/gik/PageSection';
import { HTMLParser } from '@gik/ui/HTMLParser';
import React from 'react';
import animationCalendar from '../../assets/calendar/cal.json';
import animationFundraisingDesktop from '../../assets/fundraising/fundraising-desktop.json';
import animationFundraising from '../../assets/fundraising/fundraising.json';
import animationUpdates from '../../assets/updates/updates.json';
import animationWishlist from '../../assets/wishlist/wishlist.json';

const blockName = 'features';

function FeaturesComp() {
  const bem = bemBlock(blockName);
  const featuresPageHref = '/features';
  const isMd = useBreakpoint(Breakpoint.MD);

  const content: Array<
    IFeatureContent &
      FeatureTileProps &
      Pick<PageSectionProps, 'decoration'> &
      Pick<PageSectionProps, 'decorationPlacement'> &
      Pick<HTMLElement, 'id'>
  > = [
    {
      id: 'care-calendar',
      variant: 'primary',
      title: i18n.t(translationKeys.titleCalendar),
      description: <HTMLParser rawHtml={i18n.t(translationKeys.descriptionCalendar)} />,
      testimonial: CalendarTestimonial,
      linkLabel: i18n.t(translationKeys.linkCalendar),
      linkIcon: `${getAssetsUrl()}marketing/icons/calendar.png`,
      linkRef: `${featuresPageHref}#${calendarNavId}`,
      decoration: 'foliage-pointy',
      decorationPlacement: 'left',
      aside: (
        <Animation
          className={bem('animation')}
          data={animationCalendar}
          mode={onScrollMode}
          assetsPrepend={`${getAssetsUrl()}homepage/calendar/images/`}
        />
      ),
    },
    {
      id: 'wishlist',
      variant: 'danger',
      title: i18n.t(translationKeys.titleWishlist),
      description: <HTMLParser rawHtml={i18n.t(translationKeys.descriptionWishlist)} />,
      testimonial: WishlistTestimonial,
      linkLabel: i18n.t(translationKeys.linkWishlist),
      linkIcon: `${getAssetsUrl()}marketing/icons/wishlist.png`,
      linkRef: `${featuresPageHref}#${wishlistNavId}`,
      decoration: 'flowers',
      decorationPlacement: 'right',
      aside: (
        <Animation
          className={bem('animation')}
          data={animationWishlist}
          mode={onScrollMode}
          assetsPrepend={`${getAssetsUrl()}homepage/wishlist/images/`}
          intersectionRatioThreshold={0.6}
        />
      ),
      reversed: true,
    },
    {
      id: 'updates',
      variant: 'secondary',
      title: i18n.t(translationKeys.titleUpdates),
      description: <HTMLParser rawHtml={i18n.t(translationKeys.descriptionUpdates)} />,
      testimonial: UpdatesTestimonial,
      linkLabel: i18n.t(translationKeys.linkUpdates),
      linkIcon: `${getAssetsUrl()}marketing/icons/updates.png`,
      linkRef: `${featuresPageHref}#${updatesNavId}`,
      decoration: 'foliage-round',
      decorationPlacement: 'left',
      aside: (
        <Animation
          className={bem('animation')}
          data={animationUpdates}
          mode={onScrollMode}
          assetsPrepend={`${getAssetsUrl()}homepage/updates/images/`}
          intersectionRatioThreshold={0.6}
        />
      ),
    },
    {
      id: 'donations',
      variant: 'success',
      title: i18n.t(translationKeys.titleFundraising),
      description: <HTMLParser rawHtml={i18n.t(translationKeys.descriptionFundraising)} />,
      testimonial: FundraisingTestimonial,
      linkLabel: i18n.t(translationKeys.linkFundraising),
      linkIcon: `${getAssetsUrl()}marketing/icons/fundraising.png`,
      linkRef: `${featuresPageHref}#${donationsNavId}`,
      decoration: 'foliage-small',
      decorationPlacement: 'right',
      aside: (
        <Animation
          className={bem('animation')}
          data={isMd ? animationFundraisingDesktop : animationFundraising}
          mode={onScrollMode}
          assetsPrepend={`${getAssetsUrl()}homepage/fundraising/images/`}
          intersectionRatioThreshold={0.6}
        />
      ),
      reversed: true,
    },
  ];

  return (
    <section className={bem()}>
      <Container>
        <h2 className={bem('copy-separator')}>{i18n.t(translationKeys.lifeIsFullOfUnknowns)}</h2>
      </Container>

      {content.map((props, i) => (
        <PageSection
          key={i}
          id={props.id}
          className={bem(props.id)}
          decoration={props.decoration}
          decorationPlacement={props.decorationPlacement}
          noPad
        >
          <FeatureContent
            variant={props.variant}
            title={props.title}
            description={props.description}
            aside={props.aside}
            reversed={props.reversed}
          >
            <FeatureTile
              variant={props.variant}
              linkLabel={props.linkLabel}
              linkIcon={props.linkIcon}
              linkRef={props.linkRef}
              testimonial={props.testimonial}
              reversed={props.reversed}
            />
          </FeatureContent>
        </PageSection>
      ))}
    </section>
  );
}

export const Features = withComponentErrorBoundary(FeaturesComp);
