import type { OrganizationLDSchema } from '@gik/seo';
import authorSDGenerator from '@gik/seo/structuredDataContentGenerators/authorSDGenerator';

export default function organizationSDGenerator(): OrganizationLDSchema {
  return {
    '@type': 'Organization',
    name: 'Give InKind',
    legalName: 'Give InKind, Inc',
    slogan: 'Organize Support for Anyone, Anywhere.',
    founder: authorSDGenerator({ fullName: 'Laura Malcolm', slug: 'laura-malcolm' }),
    foundingDate: '2016-04-01',
    foundingLocation: {
      '@type': 'Place',
      address: 'Tacoma, Washington, United States',
    },
    logo: 'https://cms.giveinkind.com/wp-content/uploads/2020/07/logo-300x300.jpg',
    url: 'https://www.giveinkind.com',
  };
}
