import Link from '@/components/Link';
import type { ITestimonial } from '@gik/core/models/gik/ITestimonial';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { HomepageTestimonial } from '@gik/homepage/components/HomepageTestimonial/HomepageTestimonial';
import { Image } from '@gik/ui/Image';
import { Separator } from '@gik/ui/Separator';
import type { UIVariant } from '@gik/ui/types';
import React from 'react';

export interface FeatureTileProps {
  variant: UIVariant | 'danger' | 'success';
  linkIcon: string;
  linkLabel: string;
  linkRef: string;
  testimonial: Partial<ITestimonial>;
  reversed?: boolean;
}

const blockName = 'feature-tile';

function FeatureTileComp({ variant, linkIcon, linkLabel, linkRef, testimonial, reversed = false }: FeatureTileProps) {
  const bem = bemBlock(blockName);

  // TODO align separators again
  return (
    <div className={bem(null, [{ reversed: reversed }, { [variant]: variant }])}>
      <Separator className={bem('separator')} />
      <HomepageTestimonial testimonial={testimonial} reversed={reversed} />
      <Separator className={bem('separator')} />
      <Link href={linkRef} className={bem('link')}>
        <Image className={bem('learn-more-icon')} src={linkIcon} />
        <div className={bem('learn-more-copy')}>{linkLabel}</div>
      </Link>
    </div>
  );
}

export const FeatureTile = withComponentErrorBoundary(FeatureTileComp);
