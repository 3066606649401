import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export type InViewportFallbackProps = {
  height?: string;
  className?: string;
  style?: React.CSSProperties;
};

export function InViewportFallback({
  children,
  className,
  ...otherProps
}: React.PropsWithChildren<InViewportFallbackProps>) {
  const bem = useBemCN('in-viewport-fallback');

  return (
    <div {...bem(null, null, [className])} {...otherProps} role="fallback">
      {children}
    </div>
  );
}
