import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type WordpressAnnouncement from '@gik/core/models/wordpress/WordpressAnnouncement';
import type { SWRConfiguration } from 'swr';

const path = 'announcement';

function getParams() {
  return {
    _fields: 'slug,id,title.rendered,content.rendered,acf.image,acf.link,status',
  };
}

export function getWordPressAnnouncements() {
  return wpApi
    .get(path, {
      searchParams: getParams(),
    })
    .json<WordpressAnnouncement[]>();
}

export function useWordPressAnnouncements(config?: SWRConfiguration) {
  return useWPApi<WordpressAnnouncement[]>(path, getParams(), config);
}
