import React from 'react';
import type { GikIconProps } from '.';
import { GikIconDefaultSize } from '.';

export const Eat = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 47 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path d="M15.8333 24.9167V5.75" stroke="#FF0000" strokeWidth="3.83333" strokeLinecap="round" />
        <rect
          x="12"
          y="26.834"
          width="7.66667"
          height="13.4167"
          rx="3.83333"
          stroke="#FF0000"
          strokeWidth="3.83333"
          strokeLinecap="round"
        />
        <path
          d="M10.0833 5.75V11.5C10.0833 14.6756 12.6576 17.25 15.8333 17.25V17.25C19.0089 17.25 21.5833 14.6756 21.5833 11.5V5.75"
          stroke="#FF0000"
          strokeWidth="3.83333"
          strokeLinecap="round"
        />
        <path
          d="M36.9166 5.75V40.25H31.4943V26.45H27.4237C27.4237 26.45 26.6096 18.0167 29.8661 11.8833C33.1225 5.75 36.9166 5.75 36.9166 5.75Z"
          stroke="#FF0000"
          strokeWidth="3.83333"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export const EatOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 47 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path d="M15.8333 24.9167V5.75" stroke="#FF0000" strokeWidth="3.83333" strokeLinecap="round" />
        <rect
          x="12"
          y="26.834"
          width="7.66667"
          height="13.4167"
          rx="3.83333"
          stroke="#FF0000"
          strokeWidth="3.83333"
          strokeLinecap="round"
        />
        <path
          d="M10.0833 5.75V11.5C10.0833 14.6756 12.6576 17.25 15.8333 17.25V17.25C19.0089 17.25 21.5833 14.6756 21.5833 11.5V5.75"
          stroke="#FF0000"
          strokeWidth="3.83333"
          strokeLinecap="round"
        />
        <path
          d="M36.9166 5.75V40.25H31.4943V26.45H27.4237C27.4237 26.45 26.6096 18.0167 29.8661 11.8833C33.1225 5.75 36.9166 5.75 36.9166 5.75Z"
          stroke="#FF0000"
          strokeWidth="3.83333"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);
