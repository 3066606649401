import type { ITestimonial } from '@gik/core/models/gik/ITestimonial';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';

export interface IHomepageTestimonialProps {
  testimonial: Partial<ITestimonial>;
  reversed?: boolean;
}

function HomepageTestimonialComp({
  testimonial: { name, quote, title, avatar: Avatar, image },
  reversed,
}: IHomepageTestimonialProps) {
  const bem = bemBlock('homepage-testimonial');
  return (
    <div className={bem(null, [{ reversed: reversed }])}>
      <section className={bem('quote')}>{quote}</section>
      <section className={bem('user-info-container')}>
        <div className={bem('avatar')}>
          {Avatar && <Avatar />}
          {image}
        </div>
        <div className={bem('name-and-title-container')}>
          <div className={bem('name')}>{name}</div>
          <div className={bem('title')}>{title}</div>
        </div>
      </section>
    </div>
  );
}

export const HomepageTestimonial = withComponentErrorBoundary(HomepageTestimonialComp);
