import bemBlock from '@gik/core/utils/bemBlock';
import { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';
import { StartInkindCard } from '@gik/ui/gik/StartInkindCard';
import React from 'react';

const blockName = 'start-inkind';

export function StartInkind() {
  const bem = bemBlock(blockName);

  return (
    <section className={bem()}>
      <StartInkindCard trackingId={CreateClickedLocation.HOMEPAGE_HALF} />
    </section>
  );
}
