import Link from '@/components/Link';
import bemBlock from '@gik/core/utils/bemBlock';
import type { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';
import { useCreateAnalyticsController } from '@gik/create/hooks/useCreateAnalyticsController';
import { Button } from '@gik/ui/Button';
import React from 'react';

export interface IStartInkindContentProps {
  className?: string;
  trackingId?: CreateClickedLocation;
}

export const StartInkindContentBlockName = 'start-inkind-content';

export function StartInkindContent({ className, trackingId }: IStartInkindContentProps): React.ReactElement {
  const bem = bemBlock(StartInkindContentBlockName);

  const { trackCreateClicked } = useCreateAnalyticsController();

  return (
    <div className={bem(null, null, className)} onClick={() => trackingId && trackCreateClicked(trackingId)}>
      <Link href="/create">
        <Button variant="secondary" pill>
          Start an inkind page
        </Button>
      </Link>
      <br />
      <span className={bem('text')}>Truly helpful, truly free.</span>
    </div>
  );
}
