import type Author from '@gik/blog/models/Author';
import type { PersonLDSchema } from '@gik/seo';
import he from 'he';
import { stripHtml } from 'string-strip-html';

type AuthorNoContext = { fullName: string; slug: string };
type AuthorSuperType = Author | AuthorNoContext;

export default function authorSDGenerator(author: Author): PersonLDSchema;
export default function authorSDGenerator(author: AuthorNoContext): PersonLDSchema;
export default function authorSDGenerator(author: AuthorSuperType): PersonLDSchema {
  if (!author) {
    return null;
  }

  const authorSchema: PersonLDSchema = {
    '@type': 'Person',
    name: author?.fullName && he.decode(author.fullName),
    sameAs: `https://www.giveinkind.com/articles/authors/${author.slug}`,
  };

  if ((author as Author).id == null) {
    return authorSchema;
  } else {
    return {
      ...authorSchema,
      identifier: (author as Author)?.id,
      image: (author as Author)?.imageUrl,
      jobTitle: (author as Author)?.role,
      description: author && stripHtml(he.decode((author as Author).bio)).result,
    };
  }
}
