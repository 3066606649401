import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';
import ctaDesktop from '@gik/homepage/assets/create-cta/desktop/CTA-desktop.json';
import ctaMobile from '@gik/homepage/assets/create-cta/mobile/CTA-mobile.json';
import { translationKeys } from '@gik/homepage/i18n/en';
import i18n from '@gik/i18n';
import { Animation } from '@gik/ui/Animation';
import { CreatePageFor } from '@gik/ui/gik/CreatePageFor/CreatePageFor';
import { PageSection } from '@gik/ui/gik/PageSection';
import React from 'react';

const blockName = 'homepage-footer';

export interface IHomepageFooterProps {}

function HomepageFooterComp(_props: IHomepageFooterProps) {
  const bem = bemBlock(blockName);
  const isMd = useBreakpoint(Breakpoint.MD);

  return (
    <PageSection className={bem(null)} variant={'aqua'} decoration={'foliage'}>
      <main className={bem('copy')}>
        <h2 className={bem('title')}>{i18n.t(translationKeys.createPageTitle)}</h2>
        <p className={bem('content')}>{i18n.t(translationKeys.createPageContent)}</p>

        <CreatePageFor trackId={CreateClickedLocation.HOMEPAGE_BOTTOM} />
      </main>

      <aside className={bem('animation-container')}>
        {isMd ? (
          <Animation
            mode={'looped'}
            data={ctaDesktop}
            assetsPrepend={`${getAssetsUrl()}homepage/create-cta/desktop/images/`}
          />
        ) : (
          <Animation
            mode={'looped'}
            data={ctaMobile}
            assetsPrepend={`${getAssetsUrl()}homepage/create-cta/mobile/images/`}
          />
        )}
      </aside>
    </PageSection>
  );
}

export const HomepageFooter = withComponentErrorBoundary(HomepageFooterComp);
