import type Article from '@gik/blog/models/Article';
import type { ArticleLDSchema } from '@gik/seo';
import authorSDGenerator from '@gik/seo/structuredDataContentGenerators/authorSDGenerator';
import organizationSDGenerator from '@gik/seo/structuredDataContentGenerators/organizationSDGenerator';
import he from 'he';
import { stripHtml } from 'string-strip-html';

export default function articleSDGenerator(article: Article): ArticleLDSchema {
  if (!article) {
    return null;
  }

  return {
    '@type': 'Article',
    headline: article?.title && he.decode(article.title),
    articleBody: article?.content && stripHtml(he.decode(article.content)).result,
    articleSection: article?.taxonomyItemName && he.decode(article.taxonomyItemName),
    abstract: article?.yoastMeta?.yoast_wpseo_metadesc && he.decode(article.yoastMeta.yoast_wpseo_metadesc),
    wordCount: article?.content && stripHtml(he.decode(article.content)).result.split(' ').length,
    keywords: article?.yoastMeta.yoast_wpseo_metakeywords,
    inLanguage: 'English',
    thumbnailUrl: article?.largeImageUrl,
    image: [
      article?.largeImageUrl,
      ...(article?.galleries ?? []).reduce((previous, current) => {
        return previous.concat(current.photos?.map(photo => photo?.src));
      }, []),
    ],
    datePublished: article?.publishedDate,
    dateModified: article?.modifiedDate,
    author:
      article?.authorFullName &&
      article?.authorSlug &&
      authorSDGenerator({ fullName: article.authorFullName, slug: article.authorSlug }),
    publisher: organizationSDGenerator(),
    url: `https://www.giveinkind.com/articles/${article?.articleSlug}`,
    offers: [...(article?.productBundle || []), ...(article?.buyingGuide || [])]?.map(product => ({
      '@type': 'Offer',
      name: product.title,
      description: stripHtml(he.decode(product.shortDescription)).result,
      identifier: product.slug,
      image: product.fullImageUrl,
      url: `https://www.giveinkind.com/products/${product.slug}`,
    })),
    mentions:
      (article?.recipe && {
        '@type': 'Recipe',
        name: article?.recipe?.title && he.decode(article.recipe.title),
        image: article?.largeImageUrl,
        prepTime: article?.recipe?.prepTime && `P0Y0M0DT0H${article.recipe.prepTime}M0S`,
        totalTime: article?.recipe?.prepTime && `P0Y0M0DT0H${article.recipe.prepTime}M0S`,
        description: article?.recipe?.description && stripHtml(he.decode(article.recipe.description)).result,
        recipeIngredient:
          article?.recipe?.ingredients && stripHtml(he.decode(article.recipe.ingredients)).result.split('\n'),
        recipeInstructions: article?.recipe?.instructions && stripHtml(he.decode(article.recipe.instructions)).result,
        sameAs: article?.recipe?.reference && stripHtml(he.decode(article.recipe.reference)).result,
        author:
          article?.authorFullName &&
          article?.authorSlug &&
          authorSDGenerator({ fullName: article.authorFullName, slug: article.authorSlug }),
        recipeCategory: article?.taxonomyItemName && he.decode(article.taxonomyItemName),
        url: `https://www.giveinkind.com/articles/${article?.articleSlug}`,
      }) ||
      undefined,
  };
}
