import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import type { UIBoxDecoration, UIBoxVariant } from '../types';

export interface IBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: UIBoxVariant;
  decoration?: UIBoxDecoration;
  rounded?: string;
  padded?: boolean;
  title?: string;
}

export function Box({
  children,
  className,
  decoration,
  variant = 'white',
  rounded = 'base',
  padded,
  title,
  ...otherProps
}: IBoxProps): React.ReactElement {
  const bem = useBemCN('box');

  return (
    <div
      {...bem(
        null,
        [
          { [variant]: variant },
          { padded },
          { [`rounded-${rounded}`]: rounded },
          { [`decoration-${decoration}`]: decoration },
        ],
        className
      )}
      {...otherProps}
    >
      {title && <header {...bem('title')}>{title}</header>}
      <div {...bem('content')}>{children}</div>
    </div>
  );
}
