import i18n from '@gik/i18n';
import { translationKeys } from '@gik/marketing/pages/CommonQuestionsPage/i18n/en';
import type { FAQPageLDSchema } from '@gik/seo';

export default function faqSDGenerator(): FAQPageLDSchema {
  const FAQItems = [...Array(25)].map((_, index) => ({
    question: i18n.t(translationKeys[`FAQ${index + 1}Title`]),
    answer: i18n.t(translationKeys[`FAQ${index + 1}Content`]),
  }));

  return {
    '@type': 'FAQPage',
    name: 'Common Questions',
    url: 'https://www.giveinkind.com/faq',
    mainEntity: FAQItems.map(faqItem => ({
      '@type': 'Question',
      name: faqItem.question,
      acceptedAnswer: { '@type': 'Answer', text: faqItem.answer },
    })),
  };
}
