import React from 'react';
import { useBemCN } from '../../utils/bemBlock';
import { InViewportFallback } from './InViewportFallback';

export type InViewportProps = {
  readonly height?: number;
  fallback?(height: number): React.ReactElement;
  readonly delay?: number;
  readonly className?: string;
  readonly skip?: boolean;
};

export function inViewportFallback() {
  return <InViewportFallback />;
}

export function InViewport({ className, children }: React.PropsWithChildren<InViewportProps>) {
  const bem = useBemCN('in-viewport');

  return <div {...bem(null, null, className)}>{children}</div>;
}
