import type { ITestimonial } from '@gik/core/models/gik/ITestimonial';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import { Image } from '@gik/ui/Image';
import React from 'react';

// TODO: add `quote` and `title` to i18n

export const CalendarTestimonial: Partial<ITestimonial> = {
  name: 'Heather',
  quote: 'Within five minutes of us sending out the email to our friends all the slots were filled.',
  title: 'Page Organizer',
  image: <Image src={`${getAssetsUrl()}core/img/avatars/av3.png`} />,
};

export const WishlistTestimonial: Partial<ITestimonial> = {
  name: 'Masha C.',
  quote: 'I am a bit overwhelmed. And very thankful.',
  title: 'Page Recipient',
  image: <Image src={`${getAssetsUrl()}core/img/avatars/av1.png`} />,
};

export const UpdatesTestimonial: Partial<ITestimonial> = {
  name: 'Amie',
  quote:
    'I was able to update my progress through my Give InKind page without having to use my social media channels - keep it separate for those who were closest to me and my care.',
  title: 'Page Recipient',
  image: <Image src={`${getAssetsUrl()}core/img/avatars/av2.png`} />,
};

export const FundraisingTestimonial: Partial<ITestimonial> = {
  name: 'Pat',
  quote: 'We appreciated having multiple ways for people to donate.',
  title: 'Page Organizer',
  image: <Image src={`${getAssetsUrl()}core/img/avatars/av4.png`} />,
};

export const testimonials: Partial<ITestimonial>[] = [
  CalendarTestimonial,
  WishlistTestimonial,
  UpdatesTestimonial,
  FundraisingTestimonial,
];
