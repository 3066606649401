import { isBrowser } from '@/utils/environment';
import { logger } from '@gik/analytics/utils/logger';
import i18n from '@gik/i18n';
import React from 'react';
import { useEffectOnce } from 'react-use';
import type { BaseAPIConfigurationObject, BaseAPIResponseData, BaseAPIResponseError } from '../../api/BaseAPIConfig';
import type { DynamicImportType } from '../../types/DynamicImportType';
import type { ErrorFallbackProps } from '../ErrorBoundary';
import { ErrorBoundary } from '../ErrorBoundary';
import { ErrorBoundaryFallback } from '../ErrorBoundary/ErrorBoundaryFallback';
import { InViewport, inViewportFallback } from '../InViewport';
import type { FetchProviderProps } from './FetchProvider';
import { FetchProvider } from './FetchProvider';

type BaseDynamicComponentProps = {
  groupId?: string;
  translations?: DynamicImportType[];
  styles?: DynamicImportType[];
  renderErrorFallback?({ errors: [Error] }): React.ReactElement;
  height?: number;
  noViewport?: boolean;
  loadingComponent?(height: number): React.ReactElement;
  className?: string;
};

type NormalDynamicComponentProps = React.PropsWithChildren<BaseDynamicComponentProps>;

type DataLoaderDynamicComponentProps<
  D extends BaseAPIResponseData,
  T = D,
  C extends BaseAPIConfigurationObject = BaseAPIConfigurationObject,
  E extends BaseAPIResponseError = BaseAPIResponseError
> = Omit<FetchProviderProps<D, T, C, E>, 'loadingComponent' | 'errorComponent'> & BaseDynamicComponentProps;

type DynamicComponentProps<
  D extends BaseAPIResponseData,
  T = D,
  C extends BaseAPIConfigurationObject = BaseAPIConfigurationObject,
  E extends BaseAPIResponseError = BaseAPIResponseError
> = NormalDynamicComponentProps | DataLoaderDynamicComponentProps<D, T, C, E>;

function DynamicComponentComp(props: NormalDynamicComponentProps): JSX.Element;
function DynamicComponentComp<
  D extends BaseAPIResponseData,
  T = D,
  C extends BaseAPIConfigurationObject = BaseAPIConfigurationObject,
  E extends BaseAPIResponseError = BaseAPIResponseError
>(props: DynamicComponentProps<D, T, C, E>): JSX.Element;

function DynamicComponentComp<
  D extends BaseAPIResponseData = never,
  T = D,
  C extends BaseAPIConfigurationObject = never,
  E extends BaseAPIResponseError = never
>(props: DynamicComponentProps<D, T, C, E>) {
  const {
    groupId: id,
    translations,
    styles,
    height,
    noViewport,
    loadingComponent = inViewportFallback,
    renderErrorFallback,
    className,
  } = props as BaseDynamicComponentProps;

  const { children } = props as NormalDynamicComponentProps;

  const { fetch, transform, children: callableChildren } = props as DataLoaderDynamicComponentProps<D, T, C, E>;

  useEffectOnce(() => {
    // load stylesheets
    if (styles) {
      styles.forEach(async fn => {
        await fn();
      });
    }

    // load translations
    if (translations) {
      translations.forEach(async fn => {
        const trans = await fn();
        i18n.addResourceBundle('en', 'translations', {
          [id]: trans,
        });
      });
    }
  });

  const fallback = React.useMemo(() => {
    function renderGenericErrorFallback({ errors }: ErrorFallbackProps) {
      logger.error(errors);
      return <ErrorBoundaryFallback maxHeight={height} errors={errors} />;
    }
    return renderErrorFallback || renderGenericErrorFallback;
  }, [renderErrorFallback, height]);

  return (
    <InViewport fallback={loadingComponent} height={height} className={className} skip={!isBrowser() || noViewport}>
      <ErrorBoundary maxHeight={height} fallback={fallback}>
        {fetch ? (
          <FetchProvider<D, T, C, E>
            fetch={fetch}
            transform={transform}
            loadingComponent={loadingComponent(height)}
            errorComponent={fallback}
          >
            {callableChildren}
          </FetchProvider>
        ) : (
          children
        )}
      </ErrorBoundary>
    </InViewport>
  );
}

// never rerender
// export const DynamicComponent = React.memo(DynamicComponentComp, () => true) as typeof DynamicComponentComp;
export const DynamicComponent = DynamicComponentComp;
