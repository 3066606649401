import { navigateTo } from '@/utils/navigateTo';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';
import { useCreateAnalyticsController } from '@gik/create/hooks/useCreateAnalyticsController';
import type { IHomepageBadgeProps } from '@gik/homepage/components/HomepageBadge/HomepageBadge';
import { translationKeys } from '@gik/homepage/i18n/en';
import i18n from '@gik/i18n';
import { openSearch } from '@gik/search/components/SearchStore';
import { Animation } from '@gik/ui/Animation';
import { AnnouncementsCarousel } from '@gik/ui/AnnouncementsCarousel/AnnouncementsCarousel';
import { Button } from '@gik/ui/Button';
import { Container } from '@gik/ui/Container';
import { SvgIcon } from '@gik/ui/SvgIcon';
import SearchIcon from '@heroicons/react/solid/SearchIcon';
import React from 'react';
import animationMobileScreen from '../../assets/img/header/header-slider-mobile.json';
import animationLargeScreen from '../../assets/img/header/header-slider.json';

const badges: IHomepageBadgeProps[] = [
  {
    title: 'Calendar',
    icon: `${getAssetsUrl()}marketing/icons/calendar.png`,
    jumpTo: 'care-calendar',
  },
  {
    title: 'Wishlist',
    icon: `${getAssetsUrl()}marketing/icons/wishlist.png`,
    jumpTo: 'wishlist',
  },
  {
    title: 'Updates',
    icon: `${getAssetsUrl()}marketing/icons/updates.png`,
    jumpTo: 'updates',
  },
  {
    title: 'Donations',
    icon: `${getAssetsUrl()}marketing/icons/fundraising.png`,
    jumpTo: 'donations',
  },
];

const blockName = 'homepage-header';

function HomepageHeaderComp() {
  const bem = bemBlock(blockName);
  const { trackCreateClicked } = useCreateAnalyticsController();
  const findAPageOnClick = React.useCallback(() => openSearch('HomePageFindAPage'), []);

  const isSmDown = useBreakpoint(Breakpoint.SM_DOWN);

  function onCreateClicked() {
    trackCreateClicked(CreateClickedLocation.HOMEPAGE_TOP);
    navigateTo('/create');
  }

  return (
    <Container className={bem()} element="header">
      <div className={bem('animation-container')}>
        {isSmDown ? (
          <Animation
            data={animationMobileScreen}
            mode="looped"
            assetsPrepend={`${getAssetsUrl()}homepage/img/header/`}
          />
        ) : (
          <Animation
            data={animationLargeScreen}
            mode="looped"
            assetsPrepend={`${getAssetsUrl()}homepage/img/header/`}
          />
        )}
      </div>

      <div className={bem('content-wrapper')}>
        <section className={bem('copy')}>
          <h1 className={bem('title')}>{i18n.t(translationKeys.introTitle)}</h1>
          <p className={bem('subtitle')}>{i18n.t(translationKeys.introSubTitle)}</p>
          <Button
            className={bem('start-page-button')}
            onClick={onCreateClicked}
            variant="secondary"
            pill
            size="lg"
            uppercase={false}
          >
            {i18n.t(translationKeys.createPageCopy)}
          </Button>
          <div className={bem('search')}>
            <Button
              className={bem('search-btn')}
              variant="white"
              size="sm"
              prepend={<SvgIcon Icon={SearchIcon} />}
              pill
              onClick={findAPageOnClick}
            >
              Find a page
            </Button>
          </div>
        </section>

        <AnnouncementsCarousel className={bem('announcements')} />

        {/* <section className={bem('buttons')}>
          <Image
            className={bem('buttons-copy')}
            src={`${getAssetsUrl()}homepage/img/header/support-made-simple.png`}
            proportion={new Proportion(258, 38)}
            asBackground
          />

          <section className={bem('badges')}>
            {badges.map((badge, i) => (
              <HomepageBadge key={i} {...badge} />
            ))}
          </section>
        </section> */}
      </div>
    </Container>
  );
}

export const HomepageHeader = withComponentErrorBoundary(HomepageHeaderComp);
