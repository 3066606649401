import { useWordPressAnnouncements } from '@gik/api/wp/WordpressAnnouncements';
import type WordpressAnnouncement from '@gik/core/models/wordpress/WordpressAnnouncement';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import { Box } from '../Box';
import { Carousel } from '../Carousel';
import { ExternalLink } from '../ExternalLink';
import { HStack } from '../HStack/HStack';
import { Image } from '../Image';
import { KeepProportions, Square } from '../KeepProportions';
import { Text } from '../Text/Text';

export type AnnouncementsCarouselProps = {} & UIComponent;

export function AnnouncementsCarousel({
  children,
  className,
  ...otherProps
}: React.PropsWithChildren<AnnouncementsCarouselProps>): React.ReactElement {
  const bem = useBemCN('announcements-carousel');

  const { data: announcements } = useWordPressAnnouncements();

  if (!announcements) return null;

  // don't render anything if announcements is empty
  if (!announcements.length) return null;

  return (
    <Box {...bem(null, null, className)} variant="white-blur">
      <Carousel
        variant="primary"
        {...otherProps}
        {...bem('carousel')}
        arrows={false}
        autoplay={true}
        autoplaySpeed={4000}
        fade={true}
        cssEase="linear"
      >
        {announcements?.map((announcement: WordpressAnnouncement, index) => (
          <ExternalLink href={announcement.acf.link} key={index} draggable={false}>
            <HStack {...bem('content-stack')}>
              <KeepProportions proportion={Square}>
                <Image {...bem('image')} src={announcement.acf.image} />
              </KeepProportions>
              <div {...bem('content-wrapper')}>
                <span {...bem('title')}>
                  <Text html>{announcement.title.rendered}</Text>
                </span>
                <span {...bem('content')}>
                  <Text html>{announcement.content.rendered}</Text>
                </span>
              </div>
            </HStack>
          </ExternalLink>
        ))}
      </Carousel>
    </Box>
  );
}
